<template>
    <div class="main-wrapper">
<!--        <div class="main-tab">考试数据</div>-->
        <el-table :data="examList" class="customTable" style="width: 100%; flex: 1;" height="1%"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                  :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
            <el-table-column prop="name" label="考试名称"></el-table-column>
            <el-table-column prop="num" label="考试人数" align="center"></el-table-column>
            <el-table-column prop="avg_score" label="平均得分" align="center"></el-table-column>
            <el-table-column label="操作" align="center" width="200px">
                <template slot-scope="scope">
                    <el-link type="primary" :underline="false">
                        <router-link class="a-link" :to="{path: '/examCenter/examData/student', query: {examId: scope.row.exam_id, level: scope.row.level }}">查看详情</router-link>
                    </el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pages-center" style="margin: 20px 0"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       layout="prev, pager, next, jumper"
                       :total="listPages.total"
                       @current-change="pageCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import {examExamList} from '@/utils/apis'
    export default {
        data() {
            return {
                examList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            // 获取列表
            getList() {
                let params = {
                  paging:1,
                  page:this.listPages.currentPageNum,
                  pageSize:this.listPages.eachPageNum,
                  type:1
                }
                examExamList(params).then((res)=>{
                  this.examList = res.data.data;
                  this.listPages.total = res.data.total;
                }).catch((err)=>{
                  console.log('err',err)
                })
            },
            // 切换页面
            pageCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getList()
            },
        }
    }
</script>

<style scoped lang="scss">
    .main-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 20px;
    }
    .main-tab {
        font-size: 16px;
        line-height: 1;
        padding-bottom: 14px;
        border-bottom: 1px solid #F1F5FF;
    }
    .a-link {
        color: inherit;
    }
</style>